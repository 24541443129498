.alertWithIcon {
  display: flex;
  padding: 0;
}

.alertWithIcon.alert-light {
  border: 1px solid #e0e0e0;
}

.alertWithIcon-icon {
  align-items: center;
  background: rgba(0, 0, 0, .05);
  border-radius: 2px 0 0 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.075);
  display: flex;
  padding: 0.75rem 1.25rem;
}

.alertWithIconSlim .alertWithIcon-icon {
  padding: 0.5rem 0.75rem;
}

.alertWithIcon-icon i {
  font-size: 1.5rem;
}

.alertWithIconSlim .alertWithIcon-icon i {
  font-size: 1rem;
}

.alertWithIcon-content {
  padding: 0.75rem 1.25rem;
  width: 100%;
}

.alertWithIconSlim .alertWithIcon-content {
  padding: 0.5rem;
}
