.orgDashCard {
  text-decoration: none !important;
  min-height: 130px;
  font-size: 1.1rem;
}

.orgDashCard:hover {
  background-color: rgba(54, 162, 235, .2);
  border: 1px rgba(54, 162, 235, 1) solid;
}

.orgDashCard i {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

@media (min-width: 767.98px) {
  .orgDashCard {
    font-size: 1.5rem;
    min-height: 200px;
  }

  .orgDashCard i {
    font-size: 2rem;
  }
}