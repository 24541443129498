.assessment-status {
  align-items: center;
  border-radius: 100%;
  color: white;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-right: .5rem;
  width: 25px;
}
