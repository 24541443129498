.Apps-row {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 220px;
}

@media (min-width: 767.98px) {
  .Apps-row {
    height: 320px !important;
  }
}