.cohort-select {
  min-width: 160px;
}

@media (min-width: 991.98px) {
  .cohort-select {
    min-width: 250px;
  }
}


.zIndex-1000 {
  z-index: 1000;
}