.vela-handoff-container {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 197px;
}

.vela-handoff-container img {
  height: 118px;
}

@media (min-width: 768px) {
  .vela-handoff-container {
    height: 275px;
    width: 388px;
  }

  .vela-handoff-container img {
    height: 173px;
  }
}