.displayItemTypeDropdown {
  min-width: initial;
  width: 95px;
}

.displayItemType {
  background: none;
  color: var(--ships-officer);
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  min-width: initial;
}

.displayItemType i {
  font-size: 1.25rem;
}

.displayItemType span {
  line-height: 0.9rem;
}

.displayItemType.active {
  outline: 0 !important;
}

