.CurrentQuizOutcomes-container {
  overflow-x: scroll;
}

.border-danger {
  border: 1px #dd3b4a solid;
}

.incorrect-response {
  display: none;
}

.guided-quiz--correct {
  color: #56b19f;
}

.custom-control-input:checked ~ .custom-control-label.guided-quiz--incorrect::before {
  border-color: #dd3b4a;
  background-color: #dd3b4a;
}

.custom-control-input:checked ~ .incorrect-response {
  display: block;
}

.custom-control-input:checked ~ .guided-quiz--incorrect {
  color: #dc3545;
}

.quiz-questions {
  padding: 0.5rem 0;
}

.quiz-question {
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
}
