.side-sheet.ImportMaterialSideSheet .card-body {
  height: calc(100vh - 117px);
}

.jer-collection-text-area {
  min-height: 300px;
}

.jer-editor-container {
  width: 100% !important;
}