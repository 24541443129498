.VelaInsights {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.vela-insights-row-clickable {
  cursor: pointer;
}

.vela-insights-row-clickable:hover {
  background-color: white !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.vela-insights-text {
  font-family: 'Arial Bold', sans-serif;
  font-size: 0.975rem;
  line-height: 1.125rem;
}

.vela-insights-text h3 {
  font-size: 1.25rem;
}

.vela-insights-text.hide-details h3 {
  margin-bottom: 0;
}

@media (min-width: 767.98px) {
  .vela-insights-text {
    font-size: 1rem;
  }
}

.velaInsightsBadges {
  position: absolute;
  top: 8px;
  right: 8px;
}

.velaInsightsBadge {
  font-size: 0.9rem;
}

@keyframes jiggle {
  0% {
    transform: rotate(-3deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

.jiggle-element {
  animation: jiggle 1s ease-in-out 1; /* Adjust duration and iteration count */
  display: inline-block; /* Or block, as needed */
}
