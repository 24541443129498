.MessageContainer {
  display: flex;
}

.MessageContainer p:last-child {
  margin-bottom: 0;
}

.MessageContainer .MessageAvatar {
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}