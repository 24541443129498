/* .turbine-modal--pageLinkShare .modal-container {
  height: 100%;
  max-width: none;
}

.turbine-modal--pageLinkShare .modal-content {
  height: 100%;
  margin: 0 !important;
}

.turbine-modal--pageLinkShare .card {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.turbine-modal--pageLinkShare .card-header {
  border-radius: 0;
} */

.turbine-modal--pageLinkShare .card-body {
  background: var(--pageLink-bkg-color);
}

@media print {
  .turbine-modal--pageLinkShare .card-body {
    background: white;
  }
}

.turbine-modal--pageLinkShare iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

#page-qrcode-container {
  margin: 0 auto;
  padding: 32px;
}

.safety-bleed {
  border: 1px dashed var(--keppel);
  position: absolute;
  transform: translateX(1px);
}

.pageLink-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#pageLink-view-dropdown .dropdown-menu {
  top: -6px !important;
}

#pageLink-leftNav {
  min-width: 260px;
  position: relative;
  z-index: 1;
}

/* #pageLinkTitle:hover {

} */

#pageLinkTitle:hover:before {
  content: '';
  border: 1px dashed lightgrey;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}