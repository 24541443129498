.ocr-container {
  display: flex !important;
  justify-content: center !important;
  position: relative;
}

.raw-text-container {
  position: relative;
  height: calc(100vh - 250px);
  margin: 15px;
  line-height: 2rem;
  overflow: hidden;
}

.raw-text-content {
  word-break: break-all;
  animation:
    blinker 6s linear infinite,
    scrollUp 20s linear infinite;
}

@media (min-width: 768px) {
  .raw-text-content {
    animation:
      blinker 6s linear infinite,
      scrollUp 30s linear infinite;
  }
}

.ocr {
  height: 250px;
}

.ocr span::before {
  content: "";
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 4%;
  width: 10px;
  height: 90%;
  background: var(--keppel);
  box-shadow: 0 0 50px 10px var(--keppel);
  clip-path: inset(0);
  animation:
    x 1s ease-in-out infinite alternate,
    y 1s ease-in-out infinite;
}

.ocr.loading span::before {
  display: none !important;
}

.ocr p::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background: var(--keppel);
  border-radius: 50%;
  position: relative;
  right: 4px;
}

.ocr.loading p::before {
  background: var(--electron-blue) !important;
}

@media (min-width: 768px) {
  .ocr p::before {
    width: 12px;
    height: 12px;
    right: 6px;
  }
}

.ocr p {
  position: absolute;
  bottom: -30px;
  color: var(--keppel);
  left: 35%;
  font-size: .5rem;
  font-weight: 600;
  animation: blinker 2.5s linear infinite;
  font-family: sans-serif;
  text-transform: uppercase;
}

.ocr.loading p {
  color: var(--electron-blue) !important;
}

@media (min-width: 768px) {
  .ocr p {
    font-size: 1rem;
    left: 38%;
  }
}

.ocr:before,
.ocr:after,
.ocr em:after,
.ocr em:before {
  border-color: var(--keppel);
  content: "";
  position: absolute;
  width: 45px;
  height: 46px;
  border-style: solid;
  border-width: 0px;
}

.ocr.loading:before,
.ocr.loading:after,
.ocr.loading em:after,
.ocr.loading em:before {
  border-color: var(--electron-blue) !important;
}

.ocr:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 5px 0 0 0;
}

.ocr:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 5px 0 0;
}

.ocr em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 5px;
}

.ocr em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 5px 0;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }

  /*
  50% {
    transform: translateY(-50%);
  }

  75% {
    transform: translateY(-75%);
  } */

  100% {
    transform: translateY(-10%);
  }
}

@keyframes move {

  0%,
  100% {
    transform: translateY(190px);
  }

  50% {
    transform: translateY(0%);
  }

  75% {
    transform: translateY(160px);
  }
}



@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }

  50% {
    clip-path: inset(0 0 0 0);
  }

  83% {
    clip-path: inset(0 -100px 0 0);
  }

}