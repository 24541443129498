/* BRANDING
 * https://flatuicolors.com/palette/in
 */

:root {
  --navbar-height: 64px;
  --navbar-locationbar-height: 84px;
  --pageLink-bkg-color: #f3f3f3;
  /* Colors */
  --ships-officer: #2c3a47;
  --keppel: #56b19f;
  /* --clear-chill: #1b9cfc; */
  --fiery-fuchsia: #b33771;
  --honey-glow: #eab543;
  --falling-star: #cad3c8;
  --georgia-peach: #fd7272;
  --sarawak-white-pepper: #f8efba;
  --sarawak-white-pepper-hover: #fbec95;
  --electron-blue: #0984e3;
  --white: #fff;
  --black: #000;
  --green-grey: #cad3c8;
  --grey: #777;
  --grey-light: #fdfdfd;
  --gray-dark: #999;
  --gray-darker: #333;
  --blue: #007bff;
  --yellow: #fbc547;
  --purple: #8e44ad;
}

.text-keppel {
  color: var(--keppel);
}

.text-ships-officer {
  color: var(--ships-officer) !important;
}

.badge-success {
  background-color: var(--keppel);
}

.bg-keppel {
  background-color: var(--keppel) !important;
}

.bg-keppel-light {
  background-color: #56b19f36;
}

.bg-danger-light {
  background-color: rgba(var(--fiery-fuchsia), .05) !important;
}

.bg-lightblue {
  background-color: rgba(54, 162, 235, 0.2);
}

.bg-purple-light {
  background-color: var(--purple);
}

.text-purple-light {
  color: var(--purple)
}