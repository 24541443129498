.AgentImage {
  align-items: center;
  background-color: #f1efef;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 0.5rem 0 0;
  outline: none !important;
  padding: 0;
  position: relative;
  text-overflow: ellipsis;
}

.AgentImage:active {
  transform: scale(0.9);
}

.AgentImageCheckMark {
  position: absolute;
  top: -10px;
  right: -4px;
  padding: 3px;
  border-radius: 100%;
}