.turbine-modal--attendanceDate .modal-content {
  padding: 0;
  margin: 0 !important;
}

@media (min-width: 767.98px) {
  .turbine-modal--attendanceDate .modal-content {
    margin: 1rem !important;
  }
}

/* .turbine-modal--attendanceDate .react-datepicker__time-container {
  width: 100%;
}

@media (min-width: 767.98px) {
  .turbine-modal--attendanceDate .react-datepicker__time-container {
    width: auto;
  }
} */
