.turbine-assistant {
  background-color: #000;
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png);
  background-repeat: repeat;
}

.defaultPromptOption {
  background-color: #212d37;
  cursor: pointer;
  font-size: .9rem;
}

@media (min-width: 767.98px) {
  .defaultPromptOption {
    font-size: 1rem;
  }
}

.defaultPromptOption:hover {
  background-color: #263745;
}

.defaultPromptOption .badge {
  font-size: 0.8rem;
  letter-spacing: .05rem;
  background: #17212a;
}

.defaultPromptOption:hover .badge {
  background: #18232c;
}