#Page-TopNav {
  height: 56px;
}

#Page-TopNav > button {
  height: 38px;
}

#turbine-page-title {
  font-size: 1.25rem;
  margin: 0;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 576px) {
  #turbine-page-title {
    font-size: 1.5rem;
    max-width: unset;
  }
}

@media (min-width: 767.98px) {
  #turbine-page-title {
    font-size: 2rem;
    max-width: unset;
  }
}