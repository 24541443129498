.nav-link.btn-link {
  background: none !important;
}

.nav-link.btn-link:hover {
  background: none !important;
  border: 0 !important;
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .nav-tabs-scrollable {
    overflow-y: hidden;
    overflow-x: auto;
  }
}