@media (min-width: 768px) {
  .side-sheet.MaterialSideSheet {
    min-width: 95% !important;
  }
}

.side-sheet.MaterialSideSheet.z-index-100 {
  z-index: 100 !important;
}

.transform-file-title {
  font-size: 1.2rem !important;
}