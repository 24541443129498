.ColorSwatches {
  display: flex;
  flex-wrap: wrap;
}

.ColorSwatches--color {
  align-items: center;
  background-color: #b3bac5;
  border: 0;
  border-radius: 0.25rem;
  color: #fff;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 0.5rem 0.5rem 0;
  outline: none !important;
  padding: 0;
  width: 3rem;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.ColorSwatches--color:active {
  transform: scale(0.9);
}

.picker {
  position: relative;
}

.popover {
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999991;
}

.popover-bkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}