.widget-listView-title {
  line-height: 1rem;
  margin-bottom: .25rem;
}

.widget-listView-body {
  font-size: .75rem;
  line-height: .75rem;
}

.widget-featured-marker {
  top: -7px;
  left: -7px;
  background: white;
  border-radius: 100%;
  padding: 2px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .15);
}


/* @media (min-width: 768px) {
  .widget-listView-title {
    line-height: 1.25rem;
    margin-bottom: .5rem;
  }

  .widget-listView-body {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 1rem;
  }
} */