.breadcrumb-container {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
}

.breadcrumb-container .breadcrumb {
  margin: 0;
  padding: 0;
}

.breadcrumb-container .breadcrumb ol {
  padding: 0;
}

.breadcrumb {
  flex-wrap: nowrap !important;
}

.breadcrumb-item {
  color: rgba(44, 58, 71, 0.7);
}

.breadcrumb-item a {
  color: #0056b3;
}

.breadcrumb-item+.breadcrumb-item {
  white-space: nowrap;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 767.98px) {
  .breadcrumb-item+.breadcrumb-item {
    max-width: initial;
  }
}