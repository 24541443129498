.manage-content-textarea-lg {
  min-height: 400px;
}

.manage-content-textarea-md {
  min-height: 300px;
}

.manage-content-textarea-sm {
  min-height: 200px;
}

.manage-content-textarea-xs {
  min-height: 100px;
}

.fa-caret-right,
.fa-caret-down {
  display: inline-flex;
  justify-content: center;
  margin-right: 0.25rem !important;
  width: 12px;
}

[aria-expanded="true"] .fa-caret-right,
[aria-expanded="false"] .fa-caret-down {
  display: none;
}

.item-drag-handle {
  align-items: center;
  background-color: #f7f9fa;
  border: 0;
  border-right: 1px solid #d3dce0;
  color: #606c7c;
  cursor: grab !important;
  display: flex;
  height: 80px;
  justify-content: center;
  padding: 0;
  position: relative;
  font-size: 10px;
  transition: background-color 0.2s ease-in-out;
  width: 1.25rem;
}

.bd-callout {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.bd-callout.bd-callout-danger {
  border-left-color: #d9534f;
}
