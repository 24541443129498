.ruler-h,
.ruler-w {
  position: absolute;
}

.ruler-h-inner:before,
.ruler-h-inner:after,
.ruler-w-inner:before,
.ruler-w-inner:after {
  content: '';
  display: block;
  border-color: black;
  /* transform: translateX(1px); */
  position: absolute;
}

.ruler-w-dimensions,
.ruler-h-dimensions {
  background: var(--pageLink-bkg-color);
  color: black;
  padding: .25rem;
  position: relative;
  z-index: 1;
}

/* HEIGHT */
.ruler-h {
  top: 0px;
  left: 0px;
  transform: translateX(calc(-100% - 20px)) translateY(0px);
}

.ruler-h-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ruler-h-inner:before,
.ruler-h-inner:after {
  width: 11px;
}

.ruler-h-inner:before {
  border-top: 1px solid black;
  top: 0;
}

.ruler-h-inner:after {
  border-bottom: 1px solid black;
  bottom: 0;
}

.ruler-h-line {
  position: absolute;
  left: 50%;
  height: 100%;
  width: 100%;
  border-left: 1px solid black;
}

/* WIDTH */
.ruler-w {
  left: 0;
  bottom: 0px;
  transform: translateY(calc(100% + 20px)) translateX(0px);
}

.ruler-w-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ruler-w-inner:before,
.ruler-w-inner:after {
  height: 11px;
}

.ruler-w-inner:before {
  border-right: 1px solid black;
  left: 0;
}

.ruler-w-inner:after {
  border-left: 1px solid black;
  right: 0;
}

.ruler-w-line {
  position: absolute;
  left: 0px;
  bottom: 50%;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid black;
}