.table-dark .NumberCell>span {
  color: #c0c7cc !important;
  border-color: transparent !important;
  background-color: #25282c !important;
}

.table-dark .bg-success {
  background-color: #0d401a !important;
}

.theme-dark .PaginationSupabase {
  background-color: #121212 !important;
  border-color: #464d55 !important;
}

.theme-dark .PaginationSupabase-range {
  color: white !important;
}