* {
  box-sizing: border-box;
}

body {
  background-color: #f8f9fa;
  color: #3f5366;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

/* FIX TOOLTIP */
.__react_component_tooltip {
  padding: 10px;
}

.__react_component_tooltip.place-top::before {
  z-index: -1 !important;
}

p {
  font-size: 1.125rem;
}

small p {
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);
}

.feather {
  height: 16px;
  vertical-align: text-bottom;
  width: 16px;
}

[role='main'] {
  padding-top: 18px;
}

.opacity-0 {
  opacity: 0;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

input[type=checkbox] {
  cursor: pointer !important;
}

input[type=checkbox]:disabled {
  background-color: #ededed;
  cursor: not-allowed !important;
}

.text-muted a {
  color: #1b9cfc;
}

.text-light {
  color: #ccc !important;
}

.text-3f5366 {
  color: #3f5366;
}

.text-break-all {
  word-break: break-all;
}

.text-ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.overflow-content {
  overflow-x: scroll;
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.pointer-events-none {
  pointer-events: none;
}

.privacy-blur {
  filter: blur(3px);
}

.privacy-blur * {
  filter: blur(3px);
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.border-black {
  border-color: black !important;
}

.mh-50 {
  min-height: 50px;
}

.bg-dark {
  background: var(--ships-officer) !important;
}

.bg-black {
  background: #2f2f2f;
}

.bg-000 {
  background: #000 !important;
}

.bg-none {
  background: none;
}

.bg-2f2f2f {
  background: #2f2f2f;
}

.bg-e9f4ff {
  background: #e9f4ff;
}

.bg-ffe9eb {
  background: #ffe9eb;
}

.bkg-pattern-dark {
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png);
  background-attachment: fixed;
  background-size: 15%;
}

.bkg-pattern-light {
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb.png);
  background-attachment: fixed;
  background-size: 15%;
}

.container-fluid {
  max-width: 1400px;
  margin: auto;
}

.overflow-x-hidden {
  overflow-x: scroll !important;
}

.Row {
  background-color: #2f2f2f;
  border-radius: .5rem;
  color: white;
  text-decoration: none !important;
  min-height: 130px;
  font-size: 1.1rem;
}

.RowHeader {
  background: rgba(255, 255, 255, .1);
  padding: .25rem .5rem;
  border-color: #5c5c5c !important;
  font-size: 1rem;
}

.RowFooter {
  background: rgba(0, 0, 0, .1);
  border-color: #5c5c5c !important;
  font-size: .9rem;
}

.LocationName {
  /* background: rgba(0, 0, 0, .5); */
  /* padding: .25rem .5rem; */
  font-size: .9rem;
}

.RowFooterItem {
  color: #999999;
  border-color: #5c5c5c !important;
  font-size: 1rem;
}

.border-primary-light {
  border-color: #b2ccf6 !important;
}

.shadow-primary-light {
  box-shadow: 0 .5rem 1rem rgba(0, 123, 255, .2) !important;
}

.border-radius-0 {
  border-radius: 0% !important;
}