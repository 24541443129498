.InputChangeValue-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.InputChangeValue-btn {
  width: 40%;
}

.InputChangeValue-valueInput,
.InputChangeValue-value {
  min-height: 82px;
  min-width: 340px;
}

.InputChangeValue-valueInput textarea {
  min-height: 160px;
}

.InputChangeValue-valueInput input {
  width: 100% !important;
}

.InputChangeValue-value {
  font-size: 1.1rem;
}

@media (min-width: 767.98px) {
  .InputChangeValue-title {
    font-size: 1.75rem;
  }

  .InputChangeValue input {
    min-width: 380px !important;
  }
}