.table-bordered.hasNavTabs {
  border-top: none !important;
}

.table.hasNavTabs td,
.table.hasNavTabs th {
  border-top: none !important;
}

.table-bordered.noExteriorBorder {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.table.noExteriorBorder td:first-child,
.table.noExteriorBorder th:first-child,
.table.noExteriorBorder td:last-child,
.table.noExteriorBorder th:last-child {
  border-left: none !important;
  border-right: none !important;
}