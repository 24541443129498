@media (min-width: 576px) {
  .side-sheet.MaterialSideSheet {
    min-width: 95% !important;
  }
}

@media (min-width: 768px) {
  .side-sheet.MaterialSideSheet {
    min-width: 80% !important;
  }
}

.side-sheet.MaterialSideSheet.z-index-100 {
  z-index: 100 !important;
}