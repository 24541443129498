.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
 /* Behind the navbar */
  padding: var(--navbar-height) 0 0;
 /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar.has-location-bar {
  padding: var(--navbar-locationbar-height) 0 0;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
 }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - var(--navbar-height));
  padding-top: 0.5rem;
  position: sticky;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.has-location-bar .sidebar-sticky {
  height: calc(100vh - var(--navbar-locationbar-height));
}

.sidebar .nav-link {
  align-items: center;
  background: none;
  border: 0;
  color: #333;
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  justify-content: flex-start;
}

.sidebar .nav-link i {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather, .sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar-module--assessments .nav-link {
  font-size: 1rem !important;
}

