.form-control:focus {
  z-index: 2;
}

.list-group-item:disabled,
.list-group-item[disabled] {
  opacity: 0.5;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #56b19f;
  background-color: #56b19f;
}

.focus-none-box-shadow [type=text]:focus{
  box-shadow: none !important;
}
