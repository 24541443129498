.turbine-side-sheet-active {
  overflow: hidden !important;
}

.side-sheet-container {
  opacity: 0;
  backdrop-filter: blur(2px);
  transition: all .25s ease;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 13;
  background: rgba(0, 0, 0, .6);
  overflow-y: auto;
  will-change: opacity, contents;
  transition: opacity 150ms ease-in-out;
}

.side-sheet {
  top: 0;
  left: 100%;
  right: 0;
  transform: translate(100%, 0);
  z-index: 13;
  min-width: 375px;
  height: 100%;
  transition: all .25s ease;
  will-change: left, right, transform;
  position: fixed;
}

.side-sheet.card-style .card-body {
  height: calc(100vh - 117px);
}

@media (min-width: 768px) {
  .side-sheet {
    min-width: 500px;
  }
}

@media (min-width: 991.98px) {
  .side-sheet {
    min-width: 700px;
  }
}


.side-sheet.animateLeft {
  /* left: 100%; */
  transform: translate(-100%, 0);
}

.side-sheet.animateRight {
  /* right: 100%; */
  transform: translate(100%, 0);
}