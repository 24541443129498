.Apprenticeship-User-Notes {
  /* height: calc(100% - 50px); */
}

.MaterialSideSheet-actions {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 0%);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}

.MaterialSideSheet-body {
  height: 330px;
  overflow: hidden;
  position: relative;
}

/* @media (min-width: 576px) {
  .MaterialSideSheet-body {
    height: px;
  }
} */

@media (min-width: 767.98px) {
  .MaterialSideSheet-body {
    height: 450px;
  }

  .noteMaterials-pagination .MaterialSideSheet-body {
    height: 385px;
  }
}

@media (min-width: 991.98px) {
  .MaterialSideSheet-body {
    height: 447px;
  }

  .noteMaterials-pagination .MaterialSideSheet-body {
    height: 382px;
  }
}

.Apprenticeship-Note {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}