.mce-content-body {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.mce-content-body menu,
.mce-content-body ol,
.mce-content-body ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}

.mce-content-body li {
  margin-bottom: .5rem;
}

.mce-content-body figure.align-left {
  float: left;
}

.mce-content-body figure.align-right {
  float: right;
}

.mce-content-body figure.image.align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767.98px) {
  .mce-content-body img {
    width: 100%;
    height: auto;
  }
}