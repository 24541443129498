@media print {
  [role="main"] {
    max-width: initial !important;
  }

  .page-break-after {
    page-break-after: always;
  }

  .print-p-2 {
    padding: 0.5rem !important;
  }
}
