.search-box-launcher .form-control {
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem 1rem;
}

/**************
  CARD HEADER
**************/

.search-box-launcher.in-card-header .search-box-launcher-input-container {
  background: white;
  /* border-left: 1px solid rgba(0, 0, 0, .125); */
  border-top-right-radius: .25rem;
}

.search-box-launcher.in-card-header .topNav-icon-dropdown-btn {
  border-left: 1px solid rgba(0, 0, 0, .125);
}

.search-box-launcher.in-card-header .input-group {
  border-top-right-radius: .25rem;
  background-color: var(--ships-officer) !important;
  color: white !important;
}

.search-box-launcher.in-card-header .search-box-launcher-mobile-icon {
  color: #f8f9fa !important;
}

/**********
  TOP NAV
**********/

.search-box-launcher.in-top-nav .search-box-launcher-input-container {
  border-right: 1px solid rgba(0, 0, 0, .125);
}


.search-box-launcher.in-top-nav .topNav-icon-dropdown-btn {
  border-right: 1px solid rgba(0, 0, 0, .125);
}

@media (min-width: 576px) {
  .search-box-launcher.in-top-nav .topNav-icon-dropdown-btn {
    border-right: 0;
  }
}