.turbine-modal--resourcePreview .modal-container {
  height: 100%;
  max-width: none;
}

.turbine-modal--resourcePreview .modal-content {
  height: 100%;
  margin: 0 !important;
}

.turbine-modal--resourcePreview .card {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.turbine-modal--resourcePreview .card-header {
  border-radius: 0;
}

.turbine-modal--resourcePreview iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.gradient-text {
  font-weight: 600;
  background: radial-gradient(circle farthest-corner at center center, #d858ea 13%, #5957db 92%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent
}