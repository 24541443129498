.onboarding {
  height: 100vh;
}

.onboarding #root,
.onboarding #root>.container-fluid {
  height: 100%;
}

.onboarding [role='main'] {
  padding-top: 0;
  height: 100%;
}

/* Loading */
.turbine-logo,
.workforce-space,
#screenLast,
.onboarding-showcase-image {
  transform: scale(0);
}

#screenFirst {
  transform: scale(1);
}

#screenFirst.scale-down,
#screenLast.scale-down {
  animation: center-animate-down 0.5s ease;
  transform: scale(0);
}

.turbine-logo.scale-up,
.workforce-space,
#screenLast,
.onboarding-showcase-image {
  animation: center-animate 0.5s ease;
  transform: scale(1);
}

.start-btn-pulse {
  display: flex;
  background: #545454;
  border-radius: 300px;
  height: 180px;
  width: 180px;
  animation: pulse 4.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
  content: "";
  z-index: 1;
  margin: auto;
  position: absolute;
  top: 0;
  left: 10px;
  right: 0;
  bottom: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

@keyframes center-animate {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes center-animate-down {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

/* Action Box */
.action-box {
  min-height: 250px;
  width: 100%;
}

@media (min-width: 767.98px) {
  .action-box {
    width: auto;
  }
}