

.topNav-apprentage img {
  max-width: none !important;
}

.topNav-apps {
  background-size: 50%;
}

@media (min-width: 576px) {
  .topNav-apps {
    background-size: 20%;
  }
}

.topNav-app-link {
  padding: .5rem;
  min-width: 88.75px;
  height: 89px;
  display: flex
;
  align-items: center;
  justify-content: center;
}

.topNav-app-link-icon {
  height: 30px;
}

#topNav-apps-menu {
  width: 330px;
  right: -65px;
  overflow-y: scroll;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#topNav-apps-menu.workforce-menu-visible {
  height: 474px;
}

#topNav-apps-menu.workforce-menu-hidden {
  height: 407px;
}

@media (min-width: 576px) {
  #topNav-apps-menu.workforce-menu-visible {
    right: 0;
    width: 520px;
    height: 374px;
  }

  #topNav-apps-menu.workforce-menu-hidden {
    right: 0;
  }

  .topNav-apps-menu-inner.workforce-menu-visible {
    display: flex !important;
  }
}

.topNav-apps-main {
  width: 100%;
}

.topNav-apps-more {
  width: 100%;
}

.topNav-apps-more.workforce-menu-hidden {
  display: flex;
  flex-wrap: wrap;
}

.topNav-apps-more a {
  width: 50%;
  border-bottom: 1px solid #dee2e6;
  padding: .75rem 1rem;
}

.topNav-apps-more a:nth-child(odd) {
  border-right: 1px solid #dee2e6;
}

.topNav-apps-more.workforce-menu-visible {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .topNav-apps-main.workforce-menu-visible {
    width: 66%;
  }

  .topNav-apps-more.workforce-menu-visible {
    display: block;
    width: 34%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .3) inset;
  }

  .topNav-apps-more {
    display: block;
  }

  .topNav-apps-more.workforce-menu-visible {
    overflow-y: scroll;
    height: 372px;
  }

  .topNav-apps-more.workforce-menu-visible a,
  .topNav-apps-more.workforce-menu-visible a:nth-child(odd) {
    border-right: none;
    border-bottom: none;
    width: 100%;
  }
}


