.contact-row {
  display: flex;
  font-size: 1.125rem;
}

.contact-label {
  min-width: 105px;
  text-align: right;
}

.edit-user-details-form label {
  font-size: 1rem;
  font-weight: bold;
}

.UsersSearchSelect-supervisors .card-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

#UserDetails-Supervisors .tw-text-sm.tw-m-0 {
  display: flex;
  justify-content: flex-start !important;
}

#UserDetails-Supervisors .btn-link {
  font-size: 1rem !important;
}

#UserDetails-Supervisors .tw-text-gray-400 {
  font-size: .875rem;
  /* font-size: 1rem !important; */
}