.DashboardCard.dashboard-module .card-title {
  font-size: 24px !important;
}

.DashboardCard.dashboard-module .card-img-top {
  background-size: cover;
  background-position: 0 -60px;
}

@media (min-width: 767.98px) {
  .DashboardCard.dashboard-module .card-img-top  {
    background-position: center;
  }
}