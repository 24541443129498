.references {
  background-color: #eff3f7;
}

.references .reference {
  background: white;
  margin-bottom: 0.5rem;
}

.references .reference:last-child {
  margin: 0;
}

.references .reference button {
  color: var(--ships-officer);
}
