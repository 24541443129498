#sidebar-container {
  top: 0;
}

#sidebar-container.sidebar-expanded {
  width: 230px;
}

#sidebar-container.sidebar-collapsed {
  width: 50px;
}