.NextActions {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, .5);
}

.nba-row-clickable {
  cursor: pointer;
}

.nba-row-clickable:hover {
  background-color: white !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.nba-text {
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

@media (min-width: 767.98px) {
  .nba-text {
    font-size: 1.25rem;
  }
}

.nbaBadge {
  position: absolute;
  top: 5px;
  right: 5px;
}