.Resources-title-container {
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Resources-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 767.98px) {
  .Resources-title-container {
    max-width: initial;
  }
}