.turbine-modal--manageJobProfile .modal-container {
  height: 100%;
  max-width: none;
}

.turbine-modal--manageJobProfile .modal-content {
  height: 100%;
  margin: 0 !important;
}

.turbine-modal--manageJobProfile .card {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.turbine-modal--manageJobProfile .card-header {
  border-radius: 0;
}

.turbine-modal--manageJobProfile iframe {
  border: 0;
  height: 100%;
  width: 100%;
}
