.subscription-indicator {
  background-color: #33c27f;
  height: 32px;
  width: 32px;
}

.indicator-interval-container {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1.5px solid white;
}

.indicator-interval {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
